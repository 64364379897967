<template>
  <div style="overflow-x:hidden !important; background-color: #000;">
    <LoadingComponent
    :class="[ {'loading-container' : loading }, {removeLoader : !loading},]"/>
    <!-- <button @click="deleteCookies()" v-if="showContent" style="position: absolute; z-index: 100; top: 150px; right: 100px">DELETE COOKIES</button> -->
    <AppNavbar v-if="showContent"/>
    <nuxt style="background-color: #000" v-if="showContent">
    </nuxt>
    <AppNavbar />
  </div>
</template>

<script>
export default {
  components: {
    AppNavbar: () => import('@/components/AppNavbar'),
    LoadingComponent: () => import('@/components/LoadingComponent'),
  },
  data() {
    return {
      loading: true,
      showContent: false,
      cookieIsSet: false,
      doNotDisplayLoaderAtAll: false
    }
  },
  watch: {
    loading: function(newVal, oldVal) {
      if(newVal == false) {
        console.log("Watching loader");
        document.body.style.oveflow = 'hidden'
      } else {
        document.body.style.oveflow = 'auto'
        console.log("Watching loader FINISHED");
      }
    }
  },
  mounted() {
    if (this.$route.name != 'index') {
      this.doNotDisplayLoaderAtAll = true;
      this.showContent = true;
      this.loading = false;
    } else {
      if(this.cookieIsSet == false) {
        let user = getCookie("username");
        let _this = this;
        // IF COOKIE EXISTS
        if (user != "") {
          // alert("Your Loader Should Not Be Playing after this!");
          this.loading = false;
          this.showContent = true;
        } else {
          user = 'Visitor';
          if (user != "" && user != null) {
            setTimeout(() => {
              this.loading = false;
            }, 7000);
            setTimeout(() => {
              this.showContent = true
            }, 5000);
            setCookie("username", user, 5);
            this.cookieIsSet = true;
          }
        }
      }
    }
    
    function setCookie(cname, cvalue, exdays) {
      let _this = this;
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    
    // function checkCookie() {
    //   let user = getCookie("username");
    //   let _this = this;
    //   if (user != "") {
    //     alert("Your Loader Should Not Be Playing after this! Mr." + user);
    //   } else {
    //     user = 'Visitor';
    //     if (user != "" && user != null) {
    //       setCookie("username", user, 5);
    //     }
    //   }
    // }

    // console.log(this.cookieIsSet)

    // checkCookie();
    
    // if(!this.cookieIsSet) {
    //   if(this.loading) {
        
    //   }
    // }
  },
  methods: {
    deleteCookies() {
      document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }
}
</script>

<style lang="scss">
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.loading-container {
  height: 100%;
  width: 100%;
  background-color: #000;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  opacity: 1;
}

.removeLoader {
  transition: 0.5s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
  position: fixed;
}


</style>
